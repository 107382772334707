<template>
    <Registration/>
</template>
<script>
import Registration from '@/components/Wire/Registration/Registration'
export default{
    components:{
        Registration
    }
}
</script>